<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.contact') }}</template>
    <template v-slot:header-actions>
      <v-btn icon small @click="onEdit">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-list class="mb-1">
        <v-list-item class="py-1" v-for="(phone, idx) in phones" :key="`phone-${idx}`">
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-row>
                <v-col cols="8">{{ $t('labels.phone') }}</v-col>
                <v-col cols="4" class="text-right">
                  <v-chip label small>{{ $t(`labels.${phone.type}`) }}</v-chip>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-title>{{ phone.number | formatPhone }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="phones.length > 0" />
        <v-list-item class="py-1" v-for="(email, idx) in emails" :key="`email-${idx}`">
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-row>
                <v-col cols="8">{{ $t('labels.email') }}</v-col>
                <v-col cols="4" class="text-right">
                  <v-chip label small>{{ $t(`labels.${email.type}`) }}</v-chip>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-title>{{ email.address }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="emails.length > 0" />
        <v-list-item class="py-1" v-for="(address, idx) in addresses" :key="idx">
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-row>
                <v-col cols="8">{{ $t('labels.address') }}</v-col>
                <v-col cols="4" class="text-right">
                  <v-chip label small>
                    {{ $t(`labels.${address.type}`) }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-title>
              {{ address.street_address }}, {{ address.city }}, {{ address.province }}, {{ address.postal_code }}, ({{
                address.country_code
              }})
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { formatDT } from '@argon/app/helpers'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { VWidget } from '@argon/app/components'
import { NAMESPACE } from '@argon/iam/me/store'

export default {
  name: 'UserContact',
  components: {
    VWidget
  },
  computed: {
    ...mapState(NAMESPACE, ['profile']),
    addresses() {
      return this.profile.contact.addresses || []
    },
    emails() {
      return this.profile.contact.emails || []
    },
    phones() {
      return this.profile.contact.phones || []
    }
  },
  methods: {
    onEdit() {
      this.$router.push({ name: 'editContact' })
    },
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: 'UTC' }) : ''
    }
  },
  filters: {
    formatPhone: (number) => {
      let parsed = parsePhoneNumberFromString(number)
      return parsed ? parsed.formatNational() : number
    }
  }
}
</script>
