<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>
      {{ $t('title') }}
    </template>
    <template v-slot:header-actions>
      <v-btn icon small @click="editProfile">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-list>
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('displayName') }}</v-list-item-subtitle>
            <v-list-item-title v-if="hasProfile">
              {{ profile.display }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('firstname') }}</v-list-item-subtitle>
            <v-list-item-title v-if="hasProfile">
              {{ profile.firstname }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('lastname') }}</v-list-item-subtitle>
            <v-list-item-title v-if="hasProfile">
              {{ profile.lastname }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1 ma-0">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('createdOn') }}</v-list-item-subtitle>
            <v-list-item-title v-if="hasProfile" class="grey--text text-body-2">
              {{ formatDatetime(profile.created_on) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('updatedOn') }}</v-list-item-subtitle>
            <v-list-item-title v-if="hasProfile" class="grey--text text-body-2">
              {{ formatDatetime(profile.updated_on) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { VWidget } from '@argon/app/components'
import { formatDT } from '@argon/app/helpers'
import { NAMESPACE } from '@argon/iam/me/store'
import { VFirmListItem } from '../../firms/components'

export default {
  components: {
    VWidget,
    VFirmListItem
  },
  data: () => ({
    avatar: false
  }),
  computed: {
    ...mapState(NAMESPACE, ['title', 'profile']),
    hasProfile() {
      return !!this.profile
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['uploadAvatar']),
    formatDatetime(datetime) {
      return formatDT(datetime)
    },
    editProfile() {
      this.$router.push({ name: 'editProfile' })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "createdOn": "@:labels.createdOn",
    "displayName": "@:labels.displayName",
    "edit": "@:labels.edit",
    "firstname": "@:labels.firstname",
    "lastname": "@:labels.lastname",
    "profile": "@:labels.profile",
    "title": "@:labels.profile",
    "updatedOn": "@:labels.updatedOn"
  },
  "fr": {
    "createdOn": "@:labels.createdOn",
    "displayName": "@:labels.displayName",
    "edit": "@:labels.edit",
    "firstname": "@:labels.firstname",
    "lastname": "@:labels.lastname",
    "profile": "@:labels.profile",
    "title": "@:labels.profile",
    "updatedOn": "@:labels.updatedOn"
  }
}
</i18n>
