<template>
  <VWidget enable-footer-actions>
    <template v-slot:title>{{ $t('labels.contact') }}</template>
    <template v-slot:header-actions>
      <v-btn icon small @click="dismiss">
        <v-icon small>{{ $vuetify.icons.values.close }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <phones-widget
          :phones="form.phones"
          :update-phones="updatePhones"
          :set-parent-error="setError"
          :clear-parent-error="clearError"
          key="simpl-contact-phones"
        />
        <emails-widget
          :emails="form.emails"
          :update-emails="updateEmails"
          :set-parent-error="setError"
          :clear-parent-error="clearError"
          key="simpl-contact-emails"
        />
        <addresses-widget
          :addresses="form.addresses"
          :update-addresses="updateAddresses"
          :set-parent-error="setError"
          :clear-parent-error="clearError"
          key="simpl-contact-addresses"
        />
      </v-form>
    </template>
    <template slot="footer-actions">
      <v-btn depressed color="accent" @click="submit" :loading="loading" :disabled="loading">
        {{ buttonTitle }}
      </v-btn>
    </template>
  </VWidget>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { NAMESPACE } from '@argon/iam/me/store'
import pick from 'lodash/pick'
import { VWidget, BaseForm, FormErrors } from '@argon/app/components'
import { required } from 'vuelidate/lib/validators'
import { PhonesWidget, AddressesWidget, EmailsWidget, WidgetErrorMixin } from '../../components'

export default {
  name: 'ContactCard',
  mixins: [WidgetErrorMixin],
  extends: BaseForm,
  components: {
    VWidget,
    FormErrors,
    PhonesWidget,
    AddressesWidget,
    EmailsWidget
  },
  props: {
    dismiss: { type: Function, required: true }
  },
  data: () => ({
    form: {
      phones: [],
      addresses: [],
      emails: []
    },
    collectedErrors: [],
    loading: false
  }),
  computed: {
    ...mapState(NAMESPACE, ['profile']),
    buttonTitle() {
      return this.profile ? this.$t('labels.save') : this.$t('labels.create')
    },
    isValid() {
      return !this.$v.form.$invalid && !this.hasErrors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchUser']),
    updatePhones(value) {
      this.form.phones = value
    },
    updateAddresses(value) {
      this.form.addresses = value
    },
    updateEmails(value) {
      this.form.emails = value
    },
    submit() {
      if (!this.isValid) {
        return this.$v.form.$touch()
      }
      this.loading = true
      let data = { contact: this.form }
      let payload = { id: this.profile.id, data }
      this.patchUser(payload)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    if (this.profile) {
      this.form = { ...this.profile.contact }
    }
  }
}
</script>

<style scoped>
.v-text-field {
  padding-top: 0;
  margin-top: 0;
  /* margin-bottom: 20px; */
}
</style>
