<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>
      {{ $t('labels.preferences') }}
    </template>
    <template v-slot:header-actions>
      <v-btn icon small @click="editSettings">
        <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <v-list>
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.language') }}</v-list-item-subtitle>
            <v-list-item-title v-if="settings.language">{{ $t(`labels.${settings.language}`) }}</v-list-item-title>
            <v-list-item-title v-else></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.timezone') }}</v-list-item-subtitle>
            <v-list-item-title v-if="settings.timezone">{{ settings.timezone }}</v-list-item-title>
            <v-list-item-title v-else></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.country') }}</v-list-item-subtitle>
            <v-list-item-title v-if="settings.country_code">{{
              $t(`countries.${settings.country_code}`)
            }}</v-list-item-title>
            <v-list-item-title v-else></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.province') }}</v-list-item-subtitle>
            <v-list-item-title v-if="settings.province">
              {{ $t(`regions.${settings.country_code}.${settings.province}`) }}
            </v-list-item-title>
            <v-list-item-title v-else></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-title>{{ $t('labels.notifyOnAssignation') }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon
              small
              color="success"
              v-text="$vuetify.icons.values.stateSuccess"
              v-if="settings.notify_on_assignation"
            ></v-icon>
            <v-icon
              small
              color="error"
              v-text="$vuetify.icons.values.stateFailure"
              v-if="!settings.notify_on_assignation"
            ></v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapState } from 'vuex'
import { VWidget } from '@argon/app/components'
import { NAMESPACE } from '@argon/iam/me/store'

export default {
  name: 'UserPreferences',
  components: {
    VWidget
  },
  data: () => ({}),
  computed: {
    ...mapState(NAMESPACE, ['settings'])
  },
  methods: {
    editSettings() {
      this.$router.push({ name: 'editUserPreferences' })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "edit": "@:labels.edit",
    "language": "Default Language",
    "timezone": "Default Timezone"
  },
  "fr": {
    "edit": "@:labels.edit",
    "language": "Langue par défaut",
    "timezone": "Fuseau horaire par défaut"
  }
}
</i18n>
